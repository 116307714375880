import React from 'react';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import styles from './Dependency.module.css';
import Vote from './Vote';
import { useNode } from 'utils/node';

const Dependency = ({ onVote, onDelete, dependency }) => {
    const { reloadGraphWithNode } = useNode();
    return (
        <div className={styles.dependency} key={dependency.id}>
            <Vote
                onVote={onVote}
                score={dependency.edgeScore}
                currentVote={dependency.currentVote}/>
            <span className={styles.dependencyText} onClick={() => reloadGraphWithNode(dependency)}>{dependency.label}</span>
            <FontAwesomeIcon
                className={styles.dependencyDelete}
                icon={faTimesCircle}
                onClick={onDelete}
            />
        </div>
    )
}

export default Dependency;

Dependency.propTypes = {
    onVote: PropTypes.func,
    onDelete: PropTypes.func,
    dependency: PropTypes.object
}
