import { useState } from "react";

const useInputs = initialState => {
    const [fields, setFields] = useState(initialState);
    return [
        fields,
        e => setFields({ ...fields, [e.target.id]: e.target.value }),
        () => setFields(initialState)
    ]
}

export default useInputs;