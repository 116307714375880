import cytoscape from 'cytoscape';

const colors = {
    goal: '#ff8906',
    known: '#2cb67d',
    not_known: '#35332C',
};

const borderColors = {
    active: '#F44',
    notActive: '#555248'
}

const edgeColors = {
    notActive: '#555248'
}

// TODO Just make my own graph container. Not using Cytoscape functionality anyways.
class GraphModel {
    constructor(cy = null) {
        this.cy = cy;
        
    }

    async load(nodeId, token) {
        let graph = await this.loadGraph(nodeId, token)
        graph = this.toCytoscape(graph)
        this.cy = cytoscape({
            elements: graph,
            headless: true,
        });


        return this;
    }

    /**
     * 
     * @param {object} nodeId If this is set, only the neighborhood graph is loaded.
     */
    async loadGraph(nodeId, token) {
        let ret;
        // TODO Fix in a nicer way.
        if (!nodeId) {
            nodeId = 'a0294350-a454-4d2e-8bfb-f976422a7a61'
        }
        const url = token ? `/api/nodes/${nodeId}` : `/api/nodes/unauthenticated/${nodeId}`
        const headers = token ? {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded',
        } : {}
        ret = await fetch(url, { headers })
            .catch(e => {console.log('Error fetching graph', e)})
        let graph;
        if (ret.status === 200) {
            graph = await ret.json();
            graph = this.format(graph);
        } else {
            graph = { nodes: [], edges: [] };
        }

        return graph;
    }

    async loadDependencies(node, token) {
        const subGraph = await this.loadGraph(node.id, token);
        subGraph.nodes.forEach(this.addNode, this);
        subGraph.edges.forEach(this.addEdge, this);
    }

    dependencies(node) {
        return this.deps(node, false);
    }

    dependents(node) {
        return this.deps(node, true);
    }

    deps(node, downstream) {
        const edge_criteria = downstream ? 'source' : 'target';
        const edge_value = downstream ? 'target' : 'source';
        const deps = Object.fromEntries(
            this.cy.edges()
                .map(e => e.data())
                .filter(e => e[edge_criteria] === node.id)
                .map(e => [e[edge_value], { edgeId: e.id, edgeScore: e.score, currentVote: e.currentVote }])
            );
        const nodes = this.cy.nodes()
            .map(n => n.data())
            .filter(n => Object.keys(deps).includes(n.id))
            .map(n => {
                // Carry over edgeScore and currentVote
                return Object.assign({}, n, deps[n.id]);
            })
        
        return nodes;
    }

    edgesBetween(source, target) {
        return this.cy.edges()
            .map(e => e.data())
            .filter(e => e.source === source.id)
            .filter(e => e.target === target.id);
    }

    updateStatus(status) {
        if (status) {
            status.forEach(s => {
                this.cy.$(`#${s.nodeId}`).data('status', s.status);
                this.cy.$(`#${s.nodeId}`).data('color', colors[s.status]);
            });
        }

        return this;
    }

    async markActive(node, status, token) {
        this.setBorderColor(node, borderColors.active);
        await this.loadDependencies(node, token);
        this.updateStatus(status);
        return this;
    }

    unmarkActive(node) {
        this.setBorderColor(node, borderColors.notActive);
        return this;
    }

    setBorderColor(node, color) {
        this.updateProperty(node, 'borderColor', color);
    }

    updateProperty(obj, property, value) {
        if (obj) {
            this.cy.$(`#${obj.id}`).data(property, value);
        }
    }

    remove(obj) {
        this.cy.remove(`#${obj.id}`);
        return this;
    }

    updateEditNode(node, text, label) {
        this.updateProperty(node, 'text', text);
        this.updateProperty(node, 'label', label);
        return this;
    }

    updateVote(objId, newScore, newCurrentVote) {
        const obj = { id: objId }
        this.updateProperty(obj, 'score', newScore);
        this.updateProperty(obj, 'currentVote', newCurrentVote);
        return this;
    }

    // TODO remove this when replacing cy by own graph data structure.
    // NOTE function removes as side effect.
    checkAndRemovePresent(obj) {
        const present_obj = this.cy.$(`#${obj.id}`)
        let should_add;
        if (present_obj.length === 0) {
            should_add = true;
        } else {
            if (present_obj.length > 1) {
                throw Error("There should only be one node with a given id");
            }
            if (!present_obj[0].visible) {
                this.remove(present_obj);
                should_add = true;
            } else {
                should_add = false;
            }
        }
        return should_add;
    }

    addNewlyCreatedNodes(nodes) {
        return this.addNodes(nodes.map(formatNode, this));
    }

    addNodes(nodes) {
        nodes.forEach(this.addNode, this);
        return this;
    }

    addNode(node, position) {
        if (position === undefined) {
            position = {x: Math.random(), y: Math.random()};
        }

        // Should only add a node if it's either not present or not visible.
        // In the case of an invisible node, remove it before adding the new one.
        if (this.checkAndRemovePresent(node)) {
            /* This addition of coordinates fixes the bug of that the graph disappears when adding a new node.
            This seems to be due to that the new node gets NaN coordinates, and that then spreads to all other nodes,
            Making them not show at all.
            This coordinate addition should be removed if possible, as it doesn't really belong here.
            */
            const side = 1
            node.x = position.x + 2 * side * Math.random();
            node.y = position.y + 2 * side * Math.random();

            this.cy.add({
                group: 'nodes',
                data: node,
            });
        }

        return node;
    }

    addNewlyCreatedEdges(edges) {
        return this.addEdges(edges.map(formatEdge, this));
    }

    addEdges(edges) {
        edges.forEach(this.addEdge, this);
        return this;
    }

    addEdge(edge) {
        if (!(this.exists(edge.source) && this.exists(edge.target))) {
            return;
        }
        if (this.checkAndRemovePresent(edge)) {
            this.cy.add({
                group: 'edges',
                data: edge,
            });
        }
    }

    exists(id) {
        return this.cy.$(`#${id}`).length > 0
    }

    format(graph) {
        const ret = {
            nodes: graph.nodes.map(formatNode, this),
            edges: graph.edges.map(formatEdge, this)
        };
        return ret;
    }

    toCytoscape(graph) {
        let ret = [];
        graph.nodes.forEach(n => {
            ret.push({ data: n });
        });
        graph.edges.forEach(e => {
            ret.push({ data: e });
        });
        return ret;
    }

    toSigma() {
        let ret = {};
        ret['nodes'] = this.cy.nodes().map(n => n.data()).filter(n => n.visible);
        ret['edges'] = this.cy.edges().map(e => e.data()).filter(e => e.visible);
        return ret;
    }

    getObjectData(obj) {
        const obj_ = this.cy.$(`#${obj.id}`);
        if (obj_) {
            return obj_[0].data();
        } else {
            return {};
        }
    }
}

export const formatNode = node => {
    const settings = {
        size: 3,
        color: node.node_status ? colors[node.node_status] : '#FFF',
        borderColor: borderColors.notActive,
        type: 'circle',
        visible: node.visible !== undefined ? node.visible : true,
        // TODO Deduplicate
        score: node.node_score,
        currentVote: node.current_node_vote
    };
    const toSigmaMap = { title: 'label', node_id: 'id', description: 'text' };
    return formatObject(node, settings, toSigmaMap);
}

export const formatEdge = edge => {
    const settings = {
        type: 'arrow',
        size: 2,
        count: 1,
        color: edgeColors.notActive,
        visible: edge.visible !== undefined ? edge.visible : true,
        // TODO Deduplicate
        score: edge.edge_score,
        currentVote: edge.current_edge_vote
    };
    const toSigmaMap = {from_node_id: 'source', to_node_id: 'target', edge_id: 'id'}
    return formatObject(edge, settings, toSigmaMap);
}

// Keep keys not mentioned explicitally
const formatObject = (obj, settings, toSigmaMap) => {
    let newObj = {}
    for (const k in toSigmaMap) {
        if (k in obj) {
            newObj[toSigmaMap[k]] = obj[k];
        } else if (toSigmaMap[k] in obj) {
            newObj[toSigmaMap[k]] = obj[toSigmaMap[k]];
        }
    }
    Object.assign(newObj, settings);
    return newObj;
}

export default GraphModel;
