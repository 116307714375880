import React from 'react';

import ReactMarkdown from 'react-markdown'
import math from 'remark-math'
import Tex from '@matejmazur/react-katex'

import 'katex/dist/katex.min.css'

export const latex = value => (
    <ReactMarkdown
        plugins={[math]}
        renderers={ {
            // eslint-disable-next-line
            inlineMath: ({value}) => <Tex math={value} />,
            // eslint-disable-next-line
            math: ({value}) => <Tex block math={value} />
            }}>
        {value}
    </ReactMarkdown>
)
