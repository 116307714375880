import GraphModel from '../models/graph';

export const setStatus = (node, graph, setGraph, setRefresh, token) => status => async () => { // eslint-disable-line no-unused-vars
    fetch('/api/nodeStatus', {
        method: 'POST',
        body: JSON.stringify({
            nodeId: node.id,
            status: status,
        }),
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    }).then(res => {
        if (res.status !== 200) {
            alert('Could not save the updated node status.');
        }
    });
    // Optimistic update. Update that fails will not be set back to 
    // original status in the client atm.
    graph.updateStatus([{ nodeId: node.id, status: status }]);
    setRefresh(true);
    setGraph(graph)
};

export const loadNodeStatus = async (graph, setGraph, token, setRefresh) => {
    if (token && graph) {
        const nodeStatusResp = await fetch('/api/nodeStatus/', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        let nodeStatus = await nodeStatusResp.json();
        nodeStatus = nodeStatus.map(s => ({ nodeId: s['node_id'], status: s.status }))
        graph.updateStatus(nodeStatus);
        setGraph(new GraphModel(graph.cy));
        setRefresh(true);
    }
}

export default loadNodeStatus;