import React from 'react';

import PropTypes from 'prop-types';

import Dependency from './Dependency'
import styles from './NodeComponent.module.css';
import { useDependencies } from '../utils/dependency';
import { useVote } from '../utils/vote';


export const Dependencies = ({ downstream }) => {
    const { dependencies, deleteDependency, updateDependencyScore } = useDependencies(downstream);
    const { vote, getNewScore } = useVote();

    const dependencyElems = dependencies.map(dependency => 
        Dependency({
            onVote: value => {
                const { newScore, newCurrentVote } = getNewScore(
                    {
                        score: dependency.edgeScore,
                        currentVote: dependency.currentVote
                    },
                    value
                );
                updateDependencyScore(dependency, newScore, newCurrentVote);
                vote({ votee_id: dependency.edgeId, type: "edge", value: newCurrentVote })
            },
            onDelete: () => deleteDependency(dependency),
            dependency
        })
    )
    const title = downstream ? "Learn next" : "Prerequisites";
    return (
        <div>
            {dependencies && dependencies.length > 0 && (
                <div>
                    <h2 className={styles.headline}>{title}</h2>
                    {dependencyElems}
                </div>
            )}
        </div>
    )
}


Dependencies.propTypes = {
    downstream: PropTypes.bool
}