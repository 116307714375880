import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useToken } from '../utils/token';
import { useGraph } from '../utils/graph';

import GraphComponent from 'components/GraphComponent';
import HeaderComponent from 'components/HeaderComponent';
import NodeComponent from 'components/NodeComponent';

import styles from './MainComponent.module.css';
import { loadNodeStatus } from 'utils/status';
import { ConsentBar } from './Consent';


const Main = () => {
    const { token, refreshToken } = useToken();
    const { graph, setGraph, setRefresh, graphLoadAndRefresh } = useGraph()
    const [showNodeInfo, _setShowNodeInfo] = useState(false);
    const { node_id } = useParams();

    const setShowNode = val => {
        if (val) {
            setRefresh(true);
        }
        _setShowNodeInfo(val);
    }

    useEffect(() => {
        graphLoadAndRefresh({ id: node_id });
    }, [token === undefined]); // eslint-disable-line react-hooks/exhaustive-deps
    // Reload the graph when token is first set.
    // TODO just reload the status for all nodes.

    useEffect(refreshToken, [])  // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        loadNodeStatus(graph, setGraph, token, setRefresh);
        setGraph(graph);
        setRefresh(true);
    }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

    const rightSide = () => {
        return (
            <div className={styles.right} style={{transform: showNodeInfo ? 'translateX(0)' : 'translateX(100%)'}}>
                <NodeComponent showNodeInfo={showNodeInfo} setShowNode={setShowNode}/>
            </div>
        )
    }

    return (
        <Fragment>
            <HeaderComponent/>
            <main className={styles.mainWrapper}>
                <div className={styles.left}>
                    <GraphComponent
                        setShowNode={setShowNode}
                    />
                </div>
                {rightSide()}
            </main>
            <ConsentBar/>
        </Fragment>
    )
};

export default Main;