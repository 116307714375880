import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';

import Form from './Form';
import { editNode, useNode, deleteNode } from '../utils/node';
import { useToken } from '../utils/token';
import { useGraph } from '../utils/graph';

import styles from './NodeInfo.module.css';
import Vote from './Vote';
import { useVote } from 'utils/vote';
import { setStatus } from '../utils/status';
import { latex } from '../utils/latex';


const NodeInfo = ({ setAddResourceMode }) => {
    const [ inEditMode, setEditMode ] = useState(false);
    const { activeNode, activeNodeRef, updateNodeScore, markTarget, setActiveNode, setTargetNode, setSourceNodes } = useNode();
    const { token } = useToken();
    const { graph, graphRef, setRefresh, setGraph } = useGraph();
    const { vote, getNewScore } = useVote();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const runFunctionAndClose = func => () => {
        func();
        handleClose();
    }

    useEffect(() => setEditMode(false), [activeNode.id]);

    const setStatusForNode = setStatus(activeNodeRef.current, graphRef.current, setGraph, setRefresh, token);
    const setLink = id => event => { // eslint-disable-line no-unused-vars
        navigator.clipboard.writeText(`https://dotsity.com/learn/${id}`);
    }

    let ret;
    if (inEditMode) {
        ret = (
            <div>
                <Form
                    onSubmit={editNode(activeNode, token, graph, setGraph, setEditMode)}
                    urlOrNamePlaceholder={''}
                    textPlaceholder={''}
                    initialText={activeNode.text}
                    initialUrlOrName={activeNode.label}
                    buttonText='Save'
                    preview={true}
                />
            </div>
        );
    }
    else {
        const _vote = value => {
            const { newScore, newCurrentVote } = getNewScore(
                {
                    score: activeNode.score,
                    currentVote: activeNode.currentVote
                },
                value
            );
            updateNodeScore(activeNode, newScore, newCurrentVote);
            vote({ votee_id: activeNode.id, type: "node", value: newCurrentVote });
        };
        ret = (
            <div className={styles.nodeInfo}>
                <div className={styles.nodeInfoHeader}>
                    <Vote
                        score={activeNode.score}
                        onVote={_vote}
                        currentVote={activeNode.currentVote}/>
                    <h2 className={styles.headline}>{activeNode.label}</h2>
                    <Tooltip title="Copy link to clipboard">
                        <LinkIcon
                            className={styles.link}
                            onClick={setLink(activeNode ? activeNode.id : "")}
                        />
                    </Tooltip>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{ backgroundColor: 'transparent' }} className={styles.moreActions}>
                        <MoreHorizIcon />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                        <MenuItem onClick={runFunctionAndClose(() => setEditMode(true))}>✍️ Edit</MenuItem>
                        <MenuItem onClick={runFunctionAndClose(setStatusForNode('known'))}>✓ Mark as known</MenuItem>
                        <MenuItem onClick={runFunctionAndClose(setStatusForNode('not_known'))}>❌ Mark as not known</MenuItem>
                        <MenuItem onClick={runFunctionAndClose(markTarget)}>🔗 Add prerequisites</MenuItem>
                        <MenuItem onClick={runFunctionAndClose(() => setAddResourceMode(true))}>📺 Add resource</MenuItem>
                        <MenuItem onClick={runFunctionAndClose(setStatusForNode('goal'))}>🎯 Set as goal</MenuItem>
                        <MenuItem onClick={runFunctionAndClose(
                            deleteNode(activeNode, token, graph, setGraph, setRefresh, setActiveNode, setTargetNode, setSourceNodes)
                        )}>🔥 Delete node</MenuItem>
                    </Menu>
                </div>
                {latex(activeNode.text ? activeNode.text : "[Help out by adding a description for this node.]")}
            </div>
        );
    }

    return ret;
}

NodeInfo.propTypes = {
    setAddResourceMode: PropTypes.func
}

export default NodeInfo;