import React from 'react';

import PropTypes from 'prop-types';

import styles from './Resource.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import Vote from './Vote';

const Resource = ({ onVote, onDelete, resource }) => {
    return (
        <div className={styles.resource} key={resource.resource_id}>
            <Vote score={resource.score} onVote={onVote} currentVote={resource.current_vote}/>
            <a href={resource.link_url} target="_blank" className={styles.link} rel="noopener noreferrer">
                <span>{resource.link_text}</span>
            </a>
            <FontAwesomeIcon
                className={styles.resourceDelete}
                icon={faTimesCircle}
                onClick={onDelete}
            />
        </div>
    )
}

Resource.propTypes = {
    onVote: PropTypes.func,
    onDelete: PropTypes.func,
    resource: PropTypes.object
}

export default Resource;