import React, { useContext, createContext, useEffect } from "react";
import PropTypes from 'prop-types';

import useRefState from "./useRefState";

const TokenContext = createContext();
export const useToken = () => useContext(TokenContext);

export const TokenProvider = ({ children }) => {
    const [token, setToken, tokenRef] = useRefState();

    const setRefreshTimer = () => {
        setInterval(() => refreshToken(), 1000 * 60 * 3);
    }

    const responseFacebook = async res => {
        // TODO Move out
        const tokenRes = await fetch(`/api/users/facebook/token`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + res.accessToken,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        let token_ = await tokenRes.json();
        localStorage.setItem('dotsProfileUrl', res.picture.data.url);
        setToken(token_.token);
    }

    const signup = async ({ username, password, email }) => {
        await fetch('/api/users/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, email, password })
        });
        return true;
    }

    const login = async ({ username, password }) => {
        const res = await fetch('/api/users/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password })
        });
        if (res.status !== 200) {
            return false;
        } else {
            const token_ = await res.json();
            setToken(token_.token);
            return true;
        }
    }

    const refreshToken = () => {
        fetch('/api/users/refresh/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(t => t.json())
            .then(t => { setToken(t.token); })
            .catch(_ => {console.log('Failed to refresh token');}) // eslint-disable-line no-unused-vars
    }

    const logout = () => {
        fetch('/api/users/logout', {
            method: 'POST'
        })
            .then(() => { setToken(null); })
            .catch(_ => {console.log('Error during logout');}) // eslint-disable-line no-unused-vars
    }

    useEffect(() => {
        setRefreshTimer();
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <TokenContext.Provider value={{ token, setToken, tokenRef, responseFacebook, refreshToken, logout, signup, login }}>
            {children}
        </TokenContext.Provider>
    )
}

TokenProvider.propTypes = {
    children: PropTypes.array
}
