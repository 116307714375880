import React from 'react';
import ReactDOM from 'react-dom';

import 'index.module.css';
import App from 'App';
import { checkCookiesAndInitializeAnalytics } from './utils/consent';

checkCookiesAndInitializeAnalytics();

ReactDOM.render(<App />, document.getElementById('root'));
