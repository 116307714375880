import React from "react";
import CookieConsent from "react-cookie-consent";

import { cookieName, checkCookiesAndInitializeAnalytics } from "../utils/consent";


export const ConsentBar = () => {
    const text = "This website uses cookies to ensure you get the best experience on our website. By accepting you confirm that you've read and understood the cookie- and privacy policies."
    return (
        <CookieConsent
            location="top"
            buttonText="Accept"
            cookieName={cookieName}
            enableDeclineButton
            declineButtonText="Decline"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "6px" }}
            declineButtonStyle={{ background: "#2B373B" }}
            onAccept={checkCookiesAndInitializeAnalytics}
            expires={150}
        >
            {text}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.iubenda.com/privacy-policy/69085056"
            >
                Privacy policy,
            </a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.iubenda.com/privacy-policy/69085056/cookie-policy"
            >
                Cookie policy
            </a>
        </CookieConsent>
    );
}
