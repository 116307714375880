import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';

import Form from 'components/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import styles from './NodeComponent.module.css';
import SearchComponent from "./Search";
import Resources from './Resources';
import NodeInfo from './NodeInfo';
import { submitNewNode, useNode } from '../utils/node';
import { useEdge } from '../utils/edge';
import { useGraph } from '../utils/graph';
import { useToken } from '../utils/token';
import { Dependencies } from './Dependencies';

const NodeComponent = ({ showNodeInfo, setShowNode }) => {
    const { activeNode, targetNode, sourceNodes,
            markActive, reloadGraphWithNode} = useNode();
    const { graphRef } = useGraph();
    const { token } = useToken();
    const { cancelSubmitEdges, submitEdges } = useEdge();
    const [addResourceMode, setAddResourceMode] = useState(false);

    const markActiveFromSearch = (event, data) => {
        markActive(data.value);
    }
    const reloadGraphFromSearch = (event, data) => {
        setShowNode(true);
        reloadGraphWithNode(data.value);
    }

    const nodeForm = (
        <Fragment>
            <h2 className={styles.headline}>Create new node</h2>
            <Form
                onSubmit={submitNewNode(token, graphRef.current, markActive)}
                textPlaceholder="Node description"
                urlOrNamePlaceholder="Name of new node"
                preview={true}
            />
        </Fragment>
    );
    if ((!showNodeInfo) || (!activeNode)) {
        return (<div className={styles.nonActiveSearch}>
            <SearchComponent onChange={reloadGraphFromSearch}/>
            {/* {nodeForm} */}
        </div>)
    }

    const sourceElems = Object.values(sourceNodes).map(source => (
        <p key={source.id}>
            <span>{source.label}</span>
            <FontAwesomeIcon icon={faArrowCircleRight} className={styles.connectionArrow} />
            <span>{activeNode.label}</span>
        </p>
    ));

    return (
        <div className={styles.wrapper}>
            <div className={styles.searchDiv}>
                <FontAwesomeIcon
                    id="close-node"
                    className={styles.closeNodeInfo}
                    icon={faAngleRight}
                    onClick={() => setShowNode(false)}
                />
                <SearchComponent onChange={reloadGraphFromSearch}/>
            </div>
            <NodeInfo setAddResourceMode={setAddResourceMode}/>
            {targetNode && (
                <SearchComponent onChange={markActiveFromSearch} placeholder='Find prerequisite'/>
            )}
            {/* TODO Move out of here */}
            {sourceElems && sourceElems.length > 0 && (
                <div>
                    <h2 className={styles.headline}>New prerequisites</h2>
                    {sourceElems}
                    <div className={styles.statusItems}>
                        <FontAwesomeIcon
                            id="submit-prereqs"
                            className={styles.statusItemKnown}
                            icon={faCheckSquare}
                            onClick={submitEdges}
                        />
                        <FontAwesomeIcon
                            id="cancel-prereqs"
                            className={styles.itemCancel}
                            icon={faTimesCircle}
                            onClick={cancelSubmitEdges}
                        />
                    </div>
                </div>
            )}
            <Resources addResourceMode={addResourceMode} setAddResourceMode={setAddResourceMode}/>
            <Dependencies downstream={true}/>
            <Dependencies downstream={false}/>
            {nodeForm}
        </div>
    );
}

NodeComponent.propTypes = {
    setShowNode: PropTypes.func,
    showNodeInfo: PropTypes.bool
}

export default NodeComponent;
