import React from 'react';

import PropTypes from 'prop-types';

import Form from './Form';
import Resource from './Resource';
import useResources from '../utils/resource';
import { useNode } from '../utils/node';
import { useToken } from '../utils/token';
import { useVote } from 'utils/vote';


// Both active node and token logic could probably use hooks and context
const Resources = ({ addResourceMode, setAddResourceMode }) => {
    const { activeNode } = useNode();
    const { token } = useToken();
    const { vote, getNewScore } = useVote();
    const [ resources, addResource, deleteResource, updateResourceScore ] = useResources(activeNode, token);

    const resourceElems = resources
        .map(resource => 
            Resource({
                onVote: value => {
                    const { newScore, newCurrentVote } = getNewScore(
                        {
                            score: resource.score,
                            currentVote: resource.current_vote
                        },
                        value
                    );
                    updateResourceScore(resource, newScore, newCurrentVote);
                    vote({ votee_id: resource.resource_id, type: "resource", value: newCurrentVote })
                },
                onDelete: () => deleteResource(resource.resource_id),
                resource
            })
    )
    return (
        <div>
            {resources && resources.length > 0 && (
                <div>
                    {resourceElems}
                </div>
            )}
            {addResourceMode && (
                <Form
                    onSubmit={(url, text) => {
                        setAddResourceMode(false);
                        return addResource(url, text)}
                    }
                    textPlaceholder={'New resource for "' + activeNode.label + '"'}
                    urlOrNamePlaceholder={"Resource URL"}
                    preview={false}
                />
            )}
        </div>
    )
}

Resources.propTypes = {
    addResourceMode: PropTypes.bool,
    setAddResourceMode: PropTypes.func
}

export default Resources;