import { useEffect, useState } from 'react';

import { useEdge } from './edge';
import { useGraph } from './graph';
import { useNode } from './node';
import { useToken } from './token';

export const useDependencies = downstream => {
    const { activeNode } = useNode();
    const [dependencies, setDependencies] = useState([]);
    const { graphRef } = useGraph();
    const { deleteEdge } = useEdge();
    const { token } = useToken();

    const _getDependents = async () => {
        const url = (token ? '/api/nodes/dependents/' : '/api/nodes/unauthenticated/dependents/') + activeNode.id
        const ret = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            }
        });
        const deps = await ret.json();
        graphRef.current.addNewlyCreatedNodes(deps.nodes.map(n => ({ ...n, visible: false })))
        graphRef.current.addNewlyCreatedEdges(deps.edges.map(e => ({ ...e, visible: false })))

        setDependencies(graphRef.current.dependents(activeNode))
    }

    const _getDependencies = () => {
        const deps = graphRef.current.dependencies(activeNode)
        setDependencies(deps);
    }

    const deleteDependency = dependency => {
        setDependencies(dependencies => dependencies.filter(d => d.id !== dependency.id));
        const source = downstream ? activeNode : dependency;
        const target = downstream ? dependency : activeNode; 
        const edges = graphRef.current.edgesBetween(source, target);
        if (edges.length > 0) {
            edges.forEach(deleteEdge);
        }
    }

    const updateDependencyScore = (dependency, newScore, newCurrentVote) => {
        const updatedGraph = graphRef.current.updateVote(dependency.edgeId, newScore, newCurrentVote);
        const deps = downstream ? updatedGraph.dependents(activeNode) : updatedGraph.dependencies(activeNode);
        setDependencies(deps);

    }

    useEffect(() => {
        const fetchData = async () => {
            if (downstream) {
                await _getDependents();
            } else {
                _getDependencies();
            }
        }
        fetchData();
        // NOTE graphRef is not a "valid dependency". 
        // Using graph instead doesn't work in that when going to a new
        // node by clicking a dependent, the dependents dependencies does
        // not get properly rendered/fetched/something.
    }, [activeNode.id, graphRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    return { dependencies, deleteDependency, updateDependencyScore };
}
