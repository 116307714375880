
import { initialize, trackPageview } from "analytics";
import { getCookieConsentValue } from "react-cookie-consent";

export const cookieName = "dotsCookieConsent";

export const getConsentValue = () => {
    return getCookieConsentValue(cookieName) === "true";
}

export const checkCookiesAndInitializeAnalytics = () => {
    if (getConsentValue()) {
        initialize();
        trackPageview();
    }
}
