import React from 'react';
import PropTypes from 'prop-types';
import VoteButton from './VoteButton';

import styles from './Vote.module.css';

export const UPVOTE_SCORE = 1;
const DOWNVOTE_SCORE = -1;

const Vote = ({ score = 0, onVote, currentVote = 0 }) => {
    return (
        <div className={styles.vote}>
            <VoteButton score={UPVOTE_SCORE} onClick={onVote} clicked={currentVote === UPVOTE_SCORE}/>
            <span className={styles.score}>{score}</span>
            <VoteButton score={DOWNVOTE_SCORE} onClick={onVote} clicked={currentVote === DOWNVOTE_SCORE}/>
        </div>
    )
}

Vote.propTypes = {
    score: PropTypes.number,
    onVote: PropTypes.func,
    currentVote: PropTypes.number
}

export default Vote;