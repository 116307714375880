import React from 'react';
import { Link } from 'react-router-dom';
import { useToken } from 'utils/token';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBug } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';

import styles from './HeaderComponent.module.css';

const HeaderComponent = () => {
    const { token, logout } = useToken();

    const logIn = () => {
        if (!token) {
            return (
                <Link to='/login'>
                    <Button color="primary">
                        Login
                    </Button>
                </Link>
            );
        } else {
            return (
                <div className={styles.userWrapper}>
                    {/* <div className={styles.userRewardsGold}> */}
                        {/* &#9679; <span className={styles.useReward}></span> */}
                    {/* </div> */}
                    {/* <div className={styles.profileImage}> */}
                        {/* Username to come */}
                    {/* </div> */}
                    <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className={styles.logout}
                        onClick={logout}/>
                </div>
            );
        }
    };

    return (
        <header className={styles.navbarContainer}>
            {/* <nav className={styles.nav}> */}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/sjosund/dotsity-issues">
                <FontAwesomeIcon icon={faBug} className={styles.bugs}/>
            </a>
            {/* </nav> */}
            <h1 className={styles.logo}>Dotsity</h1>
            <div className={styles.login}>{logIn()}</div>
        </header>
    );
};

export default HeaderComponent;
