import ReactGA from 'react-ga';

import config from 'config';

function ifActive(trackingFunction) {
    return config.ga.id ? trackingFunction : function noop() {};
}

export const initialize = ifActive(() => ReactGA.initialize(config.ga.id, config.ga.options));

export const trackPageview = ifActive(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
});

export const event = ifActive(ReactGA.event);

export const set = ifActive(ReactGA.set);

export const trackAddNode = name =>
    event({
        category: 'Node',
        action: 'add',
        label: name,
    });
