import { useGraph } from "./graph";
import { useNode } from "./node";
import { useToken } from "./token";
import GraphModel from '../models/graph';

export const useEdge = () => {
    const { token } = useToken();
    const { setSourceNodes, setTargetNode, targetNodeRef, sourceNodesRef } = useNode();
    const { graphRef, setGraph, setRefresh } = useGraph();

    const deleteEdge = edge => {
        // Optimistic update
        const graph_ = graphRef.current.remove(edge);
        setGraph(new GraphModel(graph_.cy));
        setRefresh(true);

        fetch('/api/edges/' + edge.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            // TODO Add error message about only being able to delete your own nodes.
            .catch(err => {
                console.log(err);
            })
    }

    const submitEdges = async event => { // eslint-disable-line no-unused-vars
        const body = JSON.stringify({
            target: targetNodeRef.current.id,
            sources: Object.values(sourceNodesRef.current).map(s => s.id),
        });
        const ret = await fetch('/api/edges', {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        const newEdges = await ret.json()
        const graph = graphRef.current.addNewlyCreatedEdges(newEdges);
        setGraph(graph);
        setSourceNodes({});
        setTargetNode(null);
        setRefresh(true);
    }

    const cancelSubmitEdges = event => { // eslint-disable-line no-unused-vars
        setSourceNodes({});
        setTargetNode(null);
    }

    return { deleteEdge, cancelSubmitEdges, submitEdges };
}
