import PropTypes from 'prop-types';
import { useToken } from 'utils/token';

export const useVote = () => {
    /*  1. Update graph with new score and vote_by_user
        2. Send request about new vote
    */
    const { token } = useToken();

    const getNewScore = (obj, value) => {
        let newScore;
        let newCurrentVote;
        if (value === obj.currentVote) {
            newScore = obj.score - value;
            newCurrentVote = 0;
        } else if ((obj.currentVote === undefined) || (obj.currentVote === 0)) {
            newScore = obj.score + value;
            newCurrentVote = value;
        } else {
            newScore = obj.score + 2 * value; // Going from upvote to downvote or vice versa.
            newCurrentVote = value;
        }
        return { newScore, newCurrentVote };
    }

    const vote = async ({ votee_id, type, value }) => {
        const ret = await fetch('/api/vote/' + votee_id, {
            method: 'POST',
            body: JSON.stringify({ value, type }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
        if (ret.status !== 200) {
            // alert('Something went wrong when voting.');
            console.log('Something went wrong when voting.');
        }
    }

    return { vote, getNewScore };
}

useVote.propTypes = {
    obj: PropTypes.object,
    type: PropTypes.oneOf(['node', 'edge', 'resource'])
}
