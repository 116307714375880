export default {
    env: process.env.NODE_ENV,
    ga: {
        id: process.env.REACT_APP_GA_TRACKING_ID,
        options: {
            debug: process.env.REACT_APP_GA_DEBUG === 'true',
            testMode: process.env.REACT_APP_GA_TEST_MODE === 'true',
        },
    },
};
