import React from 'react';

import { Link, useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import styles from './Signup.module.css';
import { useToken } from '../utils/token';
import { getConsentValue } from 'utils/consent';
import HeaderComponent from './HeaderComponent';
import { ConsentBar } from './Consent';


const Login = () => {
    const { login } = useToken();
    const history = useHistory();
    const formik = useFormik({
        initialValues: { username: "", password: "" },
        onSubmit: async props => {
            const val = getConsentValue();
            if (val !== true) {
                alert("You need to accept the use of cookies if you want to be able to log in.")
            } else {
                const successful = await login(props);
                if (successful) {
                    history.push('/learn');
                } else {
                    alert('Failed to login.');
                }
            }
        }
    })
    return (
        <div>
            <HeaderComponent/>
            <ConsentBar/>
            <div className={styles.intro}>
                <h1>Log in</h1>
                <p>No account? <Link to='/signup'>Sign up here</Link></p>
            </div>
            <form onSubmit={formik.handleSubmit} className={styles.signup}>
                <TextField
                    id="username"
                    name="username"
                    label="Username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}/>
                <TextField
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}/>
                <Button color="primary" variant="contained" type="submit">
                    Submit
                </Button>
            </form>
        </div>
    )
}

export default Login;
