import React from 'react';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
 
import styles from './Signup.module.css';
import { useToken } from '../utils/token';
import HeaderComponent from './HeaderComponent';
import { ConsentBar } from './Consent';
import { getConsentValue } from 'utils/consent';



const Signup = () => {
    const { signup } = useToken();
    const history = useHistory();

    const SignupSchema = Yup.object().shape({
        username: Yup.string()
            .min(1, 'Too short!')
            .max(250, 'Too long!')
            .matches(/^[a-zA-Z0-9_]+$/, 'Please stick to letters, numbers and underscores.')
            .required('Required'),
        password: Yup.string()
            .matches(/^[\x20-\x7F]*$/)
            .min(8, 'Too short, use between 8 and 64 characters.')
            .max(64, 'Too long.')
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords need to match.')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email')
            .max(250, 'Too long!')
            .required('Required'),
      });
    const formik = useFormik({
        initialValues: {username: "", password: "", email: "", confirmPassword: ""},
        validationSchema: SignupSchema,
        onSubmit: async props => {
            const val = getConsentValue();
            if (val !== true) {
                alert("You need to accept the use of cookies if you want to be able to sign up.")
            } else {
                await signup(props);
                alert('Welcome to Dotsity!');
                history.push('/login');
            }
        }
    })

    return (
        <div>
            <HeaderComponent/>
            <ConsentBar/>
            <div className={styles.intro}>
                <h1>Sign up</h1>
                <p className={styles.introText}>
                    Your username will be public. Please consider using an anonymous username, and not your real name, 
                    unless you are comfortable with your identity being public for the entire internet to see and identify you. 
                    By signing up you agree to our <a target="_blank" rel="noopener noreferrer" href='https://www.iubenda.com/privacy-policy/69085056'> privacy policy. </a></p>
            </div>
            <form onSubmit={formik.handleSubmit} className={styles.signup}>
                <TextField
                    id="username"
                    name="username"
                    label="Username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}/>  
                <TextField
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}/>
                <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    label="Confirm password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}/> 
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}/>  
                <Button className={styles.submit} color="primary" variant="contained" type="submit">
                    Submit
                </Button>
            </form>
        </div>
    )
}

export default Signup;
