import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { UPVOTE_SCORE } from './Vote';

import styles from './VoteButton.module.css';

const VoteButton = ({ score, onClick, clicked }) => {
    const icon = (score === UPVOTE_SCORE) ? faCaretUp : faCaretDown;
    const cl = clicked ? styles.clicked : styles.unclicked
    return (
        <FontAwesomeIcon
            className={`${styles.button} ${cl}`}
            icon={icon}
            onClick={() => onClick(score)}
        />
    )
}

VoteButton.propTypes = {
    score: PropTypes.number,
    onClick: PropTypes.func,
    clicked: PropTypes.bool
}

export default VoteButton;
