import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Main from 'components/Main';
import { GraphProvider } from 'utils/graph';
import { TokenProvider } from 'utils/token';
import { ActiveNodeProvider } from 'utils/node';
import Signup from 'components/Signup';
import Login from 'components/Login';

function App() {
    return (
        <Router>
            <TokenProvider>
                <Switch>
                    <Route path="/signup">
                        <Signup/>
                    </Route>
                    <Route path="/login">
                        <Login/>
                    </Route>
                    <Route path="/learn/:node_id?">
                        <GraphProvider>
                            <ActiveNodeProvider>
                                <Main />
                            </ActiveNodeProvider>
                        </GraphProvider>
                    </Route>
                    <Redirect from="*" to="/learn" />
                </Switch>
            </TokenProvider>
        </Router>
    );
}

export default App;
