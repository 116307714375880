import { useEffect } from 'react';

import { useToken } from './token';
import useRefState from './useRefState';

const useResources = node => {
    const [resources, setResources, resourcesRef] = useRefState([]);
    const { token } = useToken();

    const addResource = (url, text) => async (event) => {
        if (node) {
            event.preventDefault();
            await fetch('/api/resources', {
                method: 'POST',
                body: JSON.stringify({ nodeId: node.id, text, url }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            })
                .then(ret => ret.json())
                .then(newResource => {setResources([...resources, newResource])})
                .catch(e => {  // eslint-disable-line no-unused-vars
                    alert('There was an error when adding the resource :(');
                });
        }
    }

    const deleteResource = async resourceId => {
        try {
            setResources(resources.filter(r => r.resource_id !== resourceId));
            const res = await fetch('/api/resources/' + resourceId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            })
            if (res.status !== 200) {
                console.log('Something went wrong when deleting resource.');
            }
        } catch (err) {
            console.log('Error when deleting resource.');
        }
    }

    const getResources = node => {
        async function fetchData() {
            if (node) {
                const url = token ? `/api/resources/${node.id}` : `/api/resources/unauthenticated/${node.id}`
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                })
                    .then(data => data.json()) // TODO only get these once for a given node
                    .then(resources => {
                        resources.sort((a, b) => a.score > b.score ? -1 : (a.score < b.score ? 1 : 0));
                        setResources(resources);
                    })
                    .catch(e => {console.log('Error while loading resources.', e)});
            }
        }
        fetchData();
    }

    const updateResourceScore = (resource, newScore, newCurrentVote) => {
        const newResources = resourcesRef.current.map(r => {
            if (r.resource_id === resource.resource_id) {
                r.score = newScore;
                r.current_vote = newCurrentVote;
            }
            return r;
        });
        setResources(newResources);

    }
    // const vote = 

    useEffect(() => {getResources(node)}, [node.id]);   // eslint-disable-line react-hooks/exhaustive-deps

    return [ resources, addResource, deleteResource, updateResourceScore ];
}

export default useResources;