import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react'

import { formatNode } from 'models/graph';
import styles from './Search.module.css';

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);


class SearchComponent extends Component {
    constructor(props) {
       super(props);
       this.state = {
           text: '',
           options: [],
           typingTimeout: 0,
           placeholder: props.placeholder || 'Find more topics'
       };
       this.update = this.update.bind(this);
       this.query = this.query.bind(this);
    }

    update(event) {
        const text = event.target.value;
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout)
        }

        this.setState({
            text: text,
            // This timeout prohibits querying to frequently, by pausing the possibility to query for a short period of time.
            typingTimeout: setTimeout(() => {
                this.query(text)
            }, 200)
        });
    }

    async query(text) {
        if (text.length === 0) {
            this.setState({options: []});
            return
        }
        // TODO Move to utils/node
        const ret = await fetch('/api/nodes/search/' + text, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (ret.status !== 200) {
            alert('There was an error when searching :(');
        } else {
            const searchRes = await ret.json();
            const options = searchRes.map(r => ({value: formatNode(r), text: r.title, key: r.node_id}));
            this.setState({options: options});
        }
    }


    render() {
        const DropdownExampleSearchSelection = () => (
            <Dropdown
                placeholder={this.state.placeholder}
                fluid
                search
                selection
                className={styles.search}
                options={this.state.options}
                onChange={this.props.onChange}
                onSearchChange={this.update}
                id="not-a-pwd-field"
            />
        );
        return DropdownExampleSearchSelection();
    }
}

SearchComponent.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string
}

export default SearchComponent
