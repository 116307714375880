import React from 'react';
import PropTypes from 'prop-types';

import styles from './Form.module.css';
import useInputs from '../utils/form';
import { latex } from '../utils/latex';

const Form = ({
    onSubmit,
    urlOrNamePlaceholder,
    textPlaceholder,
    initialText = '',
    initialUrlOrName = '',
    buttonText = 'Create',
    preview = true
}) => {
    const [fields, setFields, resetFields] = useInputs({ "urlOrName": initialUrlOrName, "text": initialText });

    const onSubmitMod = () => event => {
        onSubmit(fields.urlOrName, fields.text)(event).then(() => {
            resetFields();
        });
    }

    return (
        <div>
            <form onSubmit={onSubmitMod()} className={styles.form}>
                <div className={styles.urlWrapper}>
                    <input
                        className={styles.sourceUrlInput}
                        type="text"
                        id="urlOrName"
                        placeholder={urlOrNamePlaceholder}
                        value={fields.urlOrName}
                        onChange={setFields}
                    />
                    <button type="submit" className={styles.submit}>
                        {buttonText}
                    </button>
                </div>
                <textarea
                    className={styles.sourceText}
                    type="text"
                    id="text"
                    maxLength={1000}
                    placeholder={textPlaceholder}
                    rows={5}
                    value={fields.text}
                    onChange={setFields}
                />
            </form>
            {preview ? latex(fields.text) : <div></div>}
        </div>
    );
}

export default Form;

Form.propTypes = {
    onSubmit: PropTypes.func,
    urlOrNamePlaceholder: PropTypes.string,
    textPlaceholder: PropTypes.string,
    initialText: PropTypes.string,
    initialUrlOrName: PropTypes.string,
    buttonText: PropTypes.string,
    preview: PropTypes.bool
}
