import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import GraphModel from 'models/graph';
import { useToken } from './token';
import useRefState from './useRefState';


const GraphContext = createContext();
export const useGraph = () => useContext(GraphContext);

export const GraphProvider = ({ children }) => {
    const [graph, setGraph, graphRef] = useRefState();
    const [refresh, setRefresh] = useState(false);
    const { token } = useToken();

    const reloadGraph = async (node = null) => {
        const graph_ = await (new GraphModel()).load(
            node ? node.id : null,
            token
        );
        return graph_
    }

    const graphLoadAndRefresh = async (node = null) => {
        const graph_ = await reloadGraph(node);
        setGraph(graph_);
        setRefresh(true);
    }

    return (
        <GraphContext.Provider value={{ graph, setGraph, graphRef, reloadGraph, graphLoadAndRefresh, refresh, setRefresh }}>
            {children}
        </GraphContext.Provider>
    )
}

GraphProvider.propTypes = {
    children: PropTypes.array
}
