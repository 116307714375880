import React, { useContext, createContext } from 'react';
import PropTypes, { node } from 'prop-types';

import { useGraph } from './graph';
import { useToken } from './token';
import useRefState from './useRefState';
import GraphModel from '../models/graph';


const ActiveNodesContext = createContext();
export const useNode = () => useContext(ActiveNodesContext);

export const ActiveNodeProvider = ({ children }) => {
    const [activeNode, setActiveNode, activeNodeRef] = useRefState()
    const [targetNode, setTargetNode, targetNodeRef] = useRefState();
    const [sourceNodes, setSourceNodes, sourceNodesRef] = useRefState({});
    const { setGraph, graphRef, reloadGraph, setRefresh } = useGraph();
    const { token } = useToken();

    const markActive = (node, graph = null) => {
        // The below happens when we're creating dependencies.
        // activeTarget is already set, and we're marking the sources.
        // The else-clause is the normal case, when we're just selecting a new node to focus on
        const graph_ = graph || graphRef.current;
        if (targetNodeRef.current) {
            // In case the node doesn't already exist in the graph, we need to add it.
            // This happens when adding a dependecy through the search field.
            node = graph_.addNode(node);
            setSourceNodes(sourceNodes => ({...sourceNodes, [node.id]: node}));
        } else {
            graph_.unmarkActive(activeNodeRef.current);
            graph_.markActive(node, null, token);
            setActiveNode(node)
        }
        // If just setting setGraph(graph_), react doesn't re-render
        // and we get a problem with lagging marker. Is there a faster way of doing this?
        setGraph(new GraphModel(graph_.cy));
        setRefresh(true);
    }

    const updateNodeScore = (node, newScore, newCurrentVote) => {
        const updatedGraph = graphRef.current.updateVote(node.id, newScore, newCurrentVote);
        setGraph(new GraphModel(updatedGraph.cy));
    }

    const markTarget = event => { // eslint-disable-line no-unused-vars
        setTargetNode(activeNode);
    }

    const reloadGraphWithNode = async node => {
        const graph_ = await reloadGraph(node);
        setTargetNode(null);
        setSourceNodes({});
        markActive(graph_.getObjectData(node), graph_);
    }

    return (
        <ActiveNodesContext.Provider value={{
            activeNode,
            setActiveNode,
            activeNodeRef,
            targetNode,
            setTargetNode,
            targetNodeRef,
            sourceNodes,
            setSourceNodes,
            sourceNodesRef,
            markActive,
            markTarget,
            reloadGraphWithNode,
            updateNodeScore}}>
            {children}
        </ActiveNodesContext.Provider>
    )
}

ActiveNodeProvider.propTypes = {
    children: PropTypes.array
}

// TODO Move into useNode
export const submitNewNode = (token, graph, markActive) => (name, text) => async event => {
    event.preventDefault();
    try {
        const ret = await fetch('/api/nodes', {
            method: 'POST',
            body: JSON.stringify({ name, text }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
        if(ret.status === 200){
            let newNode = await ret.json();
            const graph_ = graph.addNewlyCreatedNodes([newNode])
            newNode = graph_.getObjectData({ id: newNode.node_id })
            markActive(newNode, graph_);
        }
        else {
            const msg = {
                401: 'You need to be logged in to create a new node.',
                206: 'Provided malformed node id.'
            }
            if (Object.keys(msg).includes(node.status)) {
                alert(msg[node.status]);
            } else {
                alert('Something went wrong. Make sure you are logged in.');
            }
        }
    }
    catch(err) {
        console.log(err);
        alert('Could not create node due to mysterious reasons.');
    }
}

export const deleteNode = (activeNode, token, graph, setGraph, setRefresh, setActiveNode, setTargetNode, setSourceNodes) => () => { // eslint-disable-line no-unused-vars
    fetch('/api/nodes/' + activeNode.id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    }).then(ret => {
        if (ret.status === 200) {
            graph.remove(activeNode);
            setGraph(graph);
            setRefresh(true);
            setActiveNode(null);
            setTargetNode(null);
            setSourceNodes({})
        }
        else {
            if (ret.status === 403) {
                alert('Can only delete nodes that you created yourself.');
            } else {
                alert('Something went wrong when deleting node.')
            }
        }
    });
}

export const editNode = (activeNode, token, graph, setGraph, setEditMode) => (name, text) => async event => {
    event.preventDefault();
    const ret = await fetch('/api/nodes/' + activeNode.id, {
        method: 'PUT',
        body: JSON.stringify({ name, text }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
    if (ret.status !== 200) {
        alert('Something went wrong when updating the node.');
    }
    // TODO Don't update if failed.
    graph.updateEditNode(activeNode, text, name);
    setGraph(graph);
    setEditMode(false)
}