import React from 'react';

import PropTypes from 'prop-types';

import { Sigma, RandomizeNodePositions, NodeShapes, EdgeShapes } from 'react-sigma';
import Dagre from 'react-sigma/lib/Dagre';
import { useNode } from '../utils/node';
import { useGraph } from '../utils/graph';

function embedProps(elements, extraProps) {
    return React.Children.map(elements, function(element) {
        return React.cloneElement(element, extraProps);
    });
}

class Refresh extends React.Component {
    static getDerivedStateFromProps({ sigma, graph, setRefresh, refresh }) {
        if (sigma != null && refresh) {
            Refresh.removeEdge(graph, sigma);
            Refresh.removeNode(graph, sigma);
            Refresh.addNode(graph, sigma);
            Refresh.addEdge(graph, sigma);
            sigma.refresh();
            setRefresh(false);
        }
        return {};
    }

    static addEdge(graph, sigma) {
        graph.edges.forEach(e => {
            // TODO Probably should get the node by ID instead if slow.
            var existing = sigma.graph.edges().find(e2 => e2.id === e.id);
            if (existing == null) {
                sigma.graph.addEdge(e);
            }
        });
    }

    static addNode(graph, sigma) {
        graph.nodes.forEach(n => {
            // TODO Probably should get the node by ID instead if slow.
            let existing = sigma.graph.nodes().find(e => e.id === n.id);
            if (existing == null) {
                sigma.graph.addNode(n);
            }
        });
    }

    static removeNode(graph, sigma) {
        sigma.graph.nodes().forEach(n => {
            // TODO Probably should get the node by ID instead if slow.
            var updated = graph.nodes.find(e => e.id === n.id);
            if (updated != null) {
                Object.assign(n, updated);
            }
            else {
                sigma.graph.dropNode(n['id']);
            }
        });
    }

    static removeEdge(graph, sigma) {
        sigma.graph.edges().forEach(e => {
            // TODO Probably should get the edge by ID instead if slow.
            var updated = graph.edges.find(ee => ee.id === e.id);
            if (updated != null) {
                Object.assign(e, updated);
            } else {
                sigma.graph.dropEdge(e['id'])
            }
        })
    }

    render() {
        return React.createElement(
            'div',
            null,
            embedProps(this.props.children, {
                sigma: this.props.sigma,
            })
        );
    }
}

Refresh.propTypes = {
    sigma: PropTypes.object,
    graph: PropTypes.object,
    setRefresh: PropTypes.func,
    refresh: PropTypes.bool,
    children: PropTypes.array,
}

const GraphComponent = ({ setShowNode }) => {
    const { markActive } = useNode();
    const { graph, setRefresh, refresh } = useGraph();
    const markActiveFromGraph = event => {
        setShowNode(true);
        markActive(event.data.node);
    }

    if (!graph) {
        return <div></div>;
    }
    const graphToDraw = graph.toSigma();

    return (
        <Sigma
            renderer="canvas"
            graph={graphToDraw}
            settings={{ drawEdges: true, clone: false, minArrowSize: 10,
                        labelThreshold: 0, maxNodeSize: 10, labelSizeRatio: 1.2,
                        labelSize: 'proportional', scalingMode: 'outside' }}
            style={{ height: '100vh', overflow: 'auto' }}
            onClickNode={markActiveFromGraph}
        >
            <Refresh graph={graphToDraw} setRefresh={setRefresh} refresh={refresh}>
                <EdgeShapes default={'arrow'} />
                <NodeShapes borderColor="#000000" />
                <RandomizeNodePositions />
                <Dagre rankDir='BT'/>
            </Refresh>
        </Sigma>
    );
};

GraphComponent.propTypes = {
    setShowNode: PropTypes.func
}

export default GraphComponent;
